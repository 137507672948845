import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useForm } from "../../components/useForm";
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { ProductService } from '../../service/ProductService';
/* import { EventService } from '../../service/EventService'; */
import { HttpService } from "../../service/HttpService";
import { getUserData } from "../../service/AuthHelperMethods";
import { useHistory } from "react-router-dom";

import moment from 'moment';
import swal from 'sweetalert';
import classNames from 'classnames';

/* const dropdownCities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
]; */

const options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    header: {
        left: 'prev',
        center: 'title',
        right: 'next'
    },
    editable: false,
	locale: 'es'
};

/* const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860'
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e'
        }
    ]
}; */

/* const barData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'mayo', 'junio', 'Julio'],
    datasets: [
        {
            label: 'Ingresos',
            backgroundColor: '#2f4860',
            data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
            label: 'Gastos',
            backgroundColor: '#00bb7e',
            data: [28, 48, 40, 19, 86, 27, 90]
        }
    ]
}; */

const initialFValues = {
    cboMonedaO: '',
    cboMonedaD: '',
    txtFechaTasa: '',
    txtTasa: 0,
    txtContribuyente: '',
    nIdUsuario_aux: '',
};

export const Dashboard = () => {
	const [httpService] = useState(new HttpService());
	const [userData] = useState(getUserData() || null);
    const [events, setEvents] = useState(null);
    const [/* products */, setProducts] = useState(null);
    const [notificaciones, setNotificaciones] = useState(null);
    const [notificacionesVencidas, setNotificacionesVencidas] = useState(null);
	const [/* verificaTasaCambio */, setVerificaTasaCambio] = useState(true);

	const history = useHistory();
    
    
    useEffect(() => {
        const checkTasaCambio = () =>  {
            if (userData!==null) {
                httpService.getGeneralData("tasa_cambio_USD_to_BS", userData.contribuyente)
                .then(data => {
                    if (data["dFechaTasa"] === 0) {
                        setTCambioDialog(true);
                    } else {
                        const hoy = new Date();
                        const fTAsa = data["dFechaTasa"].split("-");
                        const fechaTasa = new Date(parseInt(fTAsa[0]), parseInt(fTAsa[1])-1, parseInt(fTAsa[2]), 0, 0, 0);
                        
                        var fecha1 = moment(hoy);
                        var fecha2 = moment(fechaTasa);
                        var diff = fecha1.diff(fecha2, 'days');
                        if (diff === 0){
                            setTCambioDialog(false);
                            setVerificaTasaCambio(false);
                        } else {
                            setTCambioDialog(true);
                        }
                    }
                } );
            } else {
                console.log("no hay datos de usuarios ")
            }
        };
        const productService = new ProductService();
        productService.getProductsSmall().then(data => setProducts(data));
		httpService.getGeneralData("notificaciones_for_calendar", userData.contribuyente).then(data =>{ setEvents(data) } );
		httpService.getGeneralData("notificaciones", userData.contribuyente).then(data => setNotificaciones(data) );
        httpService.getGeneralData("notificacionesVencidas", userData.contribuyente).then(data => setNotificacionesVencidas(data) );
		httpService.getOptionSelect("lista_NoBolivar").then(data => setMonedaOrigenOptions(data));
		httpService.getOptionSelect("lista_SiBolivar").then(data => {setMonedaDestinoOptions(data); setVerificaTasaCambio(true)});
		checkTasaCambio()
    }, [httpService, userData]);


	const handleVerNotificacion = (e) => {
		history.push("/notificaciones");
	};

	const [tCambioDialog, setTCambioDialog] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [monedaOrigenOptions, setMonedaOrigenOptions] = useState([]);
    const [monedaDestinoOptions, setMonedaDestinoOptions] = useState([]);

	const hideDialog = () => {
        setSubmitted(false);
        setTCambioDialog(false);
    }
	
	const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboMonedaO" in fieldValues) temp.cboMonedaO = fieldValues.cboMonedaO ? "" : "Campo Requerido.";
        if ("cboMonedaD" in fieldValues) temp.cboMonedaD = fieldValues.cboMonedaD ? "" : "Campo Requerido.";
        // if ("txtFechaTasa" in fieldValues) temp.txtFechaTasa = fieldValues.txtFechaTasa ? "" : "Campo Requerido.";
        if ("txtTasa" in fieldValues) temp.txtTasa = fieldValues.txtTasa ? "" : "Campo Requerido.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

	const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();
        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.nIdUsuario_aux = userData.idUsuario;
            values.caso = 1;
			const fecha = new Date().toISOString();
			values.txtFechaTasa = fecha;
			values.id = "";
            httpService.registrarTasaConversion(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
						swal({title: "Ups...", text: res.message, icon: "error"});
                    } else {
                        //registro exitoso
						// swal({title: "Exito", text: res.message, icon: "success"});
						swal({
							title: "Exito",
							text: res.message + " Desea registrar otra tasa?",
							icon: "success",
							buttons: {
								cancel: "No",
								catch: {
								text: "Si",
								value: "si",
								}				
							  },
							dangerMode: true,
						  })
						  .then((isConfirm) => {
							switch (isConfirm) {
								case "si":
									setTCambioDialog(false);
									setVerificaTasaCambio(false);
									resetForm();
									setTimeout(() => {
										history.push("/tasa_conversion");	
									}, 100);  
									break;
								default:
									setTCambioDialog(false);
									setVerificaTasaCambio(false);
									resetForm();
									break;
							}
						  });
                       
                    }
                })
                .catch((err) => {
					swal({title: "Ups...", text: err.message, icon: "error"});
                    setButtonDisabled(false);
                });
        } else {
			console.log("error en validacion")
		}
    };

	const tasasDialogFooter = (
        <>
            {/* <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} /> */}
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

	const sortDate = (arr, e) => {
		var valores = arr;
		valores.sort(function(a,b){
			var aFecha1 = b[e.field].split("/");
			var aFecha2 = a[e.field].split("/");
			var fecha1 = aFecha1[2] + "-" +   aFecha1[1] + "-" + aFecha1[0];
			var fecha2 = aFecha2[2] + "-" +   aFecha2[1] + "-" + aFecha2[0];
			if (e.order < 0) {
				return new Date(fecha1) - new Date(fecha2)
			} else {
				return new Date(fecha2) - new Date(fecha1);
			}
		  });
		return valores;
	}

    return (
        <div className="p-grid p-fluid dashboard">
{/*             <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                    <div className="initials" style={{ backgroundColor: '#007be5', color: '#00448f' }}><span>CF</span></div>
                    <div className="highlight-details">
                        <h4>Cargar Factura</h4>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                    <div className="initials" style={{ backgroundColor: '#ef6262', color: '#a83d3b' }}><span>CC</span></div>
                    <div className="highlight-details ">
                        <h4>Cargar Compra</h4>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                    <div className="initials" style={{ backgroundColor: '#20d077', color: '#038d4a' }}><span>CV</span></div>
                    <div className="highlight-details ">
                    <h4>Cargar Venta</h4>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                    <div className="initials" style={{ backgroundColor: '#f9c851', color: '#b58c2b' }}><span>CC</span></div>
                    <div className="highlight-details ">
                    <h4>Consultar Factura</h4>
                    </div>
                </div>
            </div> */}

            <div className="p-col-12 p-lg-6">
				<Panel header="NOTIFICACIONES RECIENTES" style={{ height: '100%' }}>
                    <DataTable value={notificaciones} className="p-datatable-customers" rows={3} paginator emptyMessage="No hay notificaciones por el momento.">
                        <Column field="texto_notificacion" header="Notificación" headerStyle={{ width: "50%" }} sortable></Column>
                        <Column field="fecha_vence" header="Vencimiento" headerStyle={{ width: "30%", textAlign: "center" }} bodyStyle={{textAlign: "center"}} sortable sortFunction={(e) => sortDate(notificaciones, e)}></Column>
                        <Column header="View" headerStyle={{ width: "20%", textAlign: "center" }} bodyStyle={{textAlign: "center"}} body={() => (
                            <>
                                <Button icon="pi pi-search" type="button" onClick={handleVerNotificacion} className="p-button-success p-mr-2 p-mb-1"></Button>
                                {/* <Button icon="pi pi-times" type="button" className="p-button-danger p-mb-1"></Button> */}
                            </>
                        )}></Column>
                    </DataTable>
				</Panel>
            </div>

            <div className="p-col-12 p-lg-6">
				<Panel header="DECLARACIONES VENCIDAS" style={{ height: '100%'}}>
                    <DataTable value={notificacionesVencidas} className="p-datatable-customers" rows={3} paginator emptyMessage="No hay notificaciones por el momento.">
                        <Column field="texto_notificacion" header="Mensaje" bodyStyle={{color: 'red'}} headerStyle={{ width: "50%" }} sortable></Column>
                        <Column field="fecha_vence" header="Vencimiento" style={{color: 'red'}} headerStyle={{ width: "30%", textAlign: "center" }} bodyStyle={{textAlign: "center", color: 'red'}} sortable sortFunction={(e) => sortDate(notificacionesVencidas, e)}></Column>
                        <Column header="View" headerStyle={{ width: "20%", textAlign: "center" }} bodyStyle={{textAlign: "center"}} body={() => (
                            <>
                                <Button icon="pi pi-search" type="button" onClick={handleVerNotificacion} className="p-button-success p-mr-2 p-mb-1"></Button>
                                {/* <Button icon="pi pi-times" type="button" className="p-button-danger p-mb-1"></Button> */}
                            </>
                        )}></Column>
                    </DataTable>
				</Panel>
            </div>

            <div className="p-col-12 p-lg-12">
                <Panel header="CALENDARIO DE DEBERES" style={{ height: '100%' }}>
                    <FullCalendar events={events} options={options} locale="es" />
                </Panel>
            </div>


            {/* SERA HABILITADO PARA UNA SEGUNA FASE */}
            {/* <div className="p-col-12 p-lg-6">
                <Panel header="Monitor de Finanzas" style={{ height: '100%' }}>
                    <div className="activity-header">
                        <div className="p-grid">
                            <div className="p-col-6">
                                <span style={{ fontWeight: 'bold' }}>Ultimos Movimientos</span>
                                <p>Updated 1 minute ago</p>
                            </div>
                            <div className="p-col-6" style={{ textAlign: 'right' }}>
                                <Button label="Actualizar" icon="pi pi-refresh" />
                            </div>
                        </div>
                    </div>

                    <ul className="activity-list">
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Ingresos</h5>
                                <div className="count">$900</div>
                            </div>
                            <ProgressBar value={95} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Gastos</h5>
                                <div className="count" style={{ backgroundColor: '#f9c851' }}>$250</div>
                            </div>
                            <ProgressBar value={15} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Impuestos</h5>
                                <div className="count" style={{ backgroundColor: '#f9c851' }}>$250</div>
                            </div>
                            <ProgressBar value={24} showValue={false} />
                        </li>
                        <li>
                            <div className="p-d-flex p-jc-between p-ai-center p-mb-3">
                                <h5 className="activity p-m-0">Facturas</h5>
                                <div className="count" style={{ backgroundColor: '#20d077' }}>$125</div>
                            </div>
                            <ProgressBar value={55} showValue={false} />
                        </li>
                    </ul>
                </Panel>
            </div>

            <div className="p-col-12 p-lg-6">
				<Panel header="Estadísticas" style={{ height: '100%' }}>
					<div className="card">
						<Chart type="bar" data={barData} />
					</div>
				</Panel>
            </div> */}

			<Dialog visible={tCambioDialog} style={{ width: '550px' }}  header="No hay Tasa de Cambio registrada, por favor registra una" modal className="p-fluid" footer={tasasDialogFooter} onHide={hideDialog} closable={false} closeOnEscape={false}>
				<InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
				<div className="p-formgrid p-grid">
					<div className="p-field p-col">
						<label htmlFor="cboMonedaO">De</label>
						<Dropdown id="cboMonedaO" name="cboMonedaO" value={values.cboMonedaO} autoFocus onChange={handleInputChange} optionValue="id" optionLabel="title" options={monedaOrigenOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMonedaO })}></Dropdown>
						{submitted && !values.cboMonedaO && <small className="p-invalid">{errors.cboMonedaO}</small>}
					</div>
				</div>
				<div className="p-formgrid p-grid">
					<div className="p-field p-col">
						<label htmlFor="cboMonedaD">A</label>
						<Dropdown id="cboMonedaD" name="cboMonedaD" value={values.cboMonedaD} autoFocus onChange={handleInputChange} optionValue="id" optionLabel="title" options={monedaDestinoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMonedaD })}></Dropdown>
						{submitted && !values.cboMonedaD && <small className="p-invalid">{errors.cboMonedaD}</small>}
					</div>
				</div>
				<div className="p-formgrid p-grid">
					<div className="p-field p-col">
					<label htmlFor="txtTasa">Tasa</label>
						<InputNumber name="txtTasa" mode="decimal" minFractionDigits={2} value={values.txtTasa} onValueChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtTasa })} />
						{submitted && !values.txtTasa && <small className="p-invalid">{errors.txtTasa}</small>}
					</div>
				</div>
				<div className="p-formgrid p-grid">
					<div className="p-field p-col">
					</div>
				</div>
			</Dialog>
        </div>
    );
}
