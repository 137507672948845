import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

import { PDFViewer } from "@react-pdf/renderer";
import Factura from "../../components/FacturaTemplate/Factura";
import ShowLoading from "../../components/ShowLoading/ShowLoading";

require('react-dom');
window.React2 = require('react');

const initialFValues = {
    txtFechaFactura: '',
    txtNroFactura: '',
    txtNroControl: '',
    cboCliente: '',
    cboMoneda: '',
    cboCondicionDePago: '',
    txtObservacion: '',
	cboMaquinaFiscal: '',    
	caso: 0,
    id: '',
	txtContribuyente: '',
    nIdUsuario_aux: 0,
};

const initialFValuesProd = {
    cboProducto: '',
    sProducto: '',
    cboUoM: '',
    sUoM: '',
    txtCantidad: 0,
    txtPrecioUnit: 0,
    cboImpuesto: '',
    sImpuesto: '',
	txtPctDescuento: 0, 
    txtDescripcion: '',
    txtContribuyente: '',
    nIdUsuario_aux: 0,

};

const initialValuesPago = {
	cboFormaDePago: '',
	cboImpuestoIGTF: '',
	txtMontoDivisas: '',
	cboMonedaDivisa: '',
	txtNroSoporte: ''
}

const array_aux = [];
export const RegistroVenta = () => {

    const history = useHistory();

    let emptyProducto = {
        cboProducto: '',
        sProducto: '',
        cboUoM: '',
        sUoM: '',
        txtCantidad: 0,
        txtPrecioUnit: 0,
        cboImpuesto: '',
        sImpuesto: '',
		txtPctDescuento: 0,
        txtDescripcion: '',
		cboMoneda: '',
		sMoneda: '',
        txtContribuyente: '',
        nIdUsuario_aux: 0
    };

	let emptyPago = {
		cboFormaDePago: 0,
		cboImpuestoIGTF: 0,
		txtMontoDivisas: '',
		cboMonedaDivisa: 0,
		txtNroSoporte: ''
	}

	const [valuesPro, setValuesPro] = useState(initialFValuesProd);
	const [errorsPro,  setErrorsPro] = useState({});
	const [valuesPago, setValuesPago] = useState(initialValuesPago);
	const [errorsPago,  /* setErrorsPago */] = useState({});

	let datosClienteSelected = {
		txtDireccionCliente : '',
		txtRifCliente : '',
		txtTelefonoCliente : '',
	}

	let location = useLocation();
	const [acceso, setAcceso] = useState("");
    const toast = useRef(null);
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [MonedaSBOptions, setMonedaSBOptions] = useState([]);
    const [ClienteOptions, setClienteOptions] = useState([]);
    const [uoMOptions, setUoMOptions] = useState([]);
    const [impuestosOptions, setImpuestosOptions] = useState([]);
    const [impuestosIGTFOptions, setImpuestosIGTFOptions] = useState([]);
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState(emptyProducto);
    
    const [httpService] = useState(new HttpService());
    const [userData] = useState(getUserData() || null);
    const [caso, setCaso] = useState(true);
    const [idFactura, setIdFactura] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [ProductoDialog, setProductoDialog] = useState(false);
    const [/* disabled */, setDisabled] = useState(true);
    const [productoOptions, setProductoOptions] = useState([]);
    const [formasDePagoOptions, setFormasDePagoOptions] = useState([]);
    const [condicionesDePagoOptions, setCondicionesDePagoOptions] = useState([]);
    const [maquinasFiscalesOptions, setMaquinasFiscalesOptions] = useState([]);
	const [dataClienteSelected, setDataClienteSelected] = useState(datosClienteSelected);
	const [txtNroControl, setTxtNroControl] = useState("");
	const [txtNroFactura, setTxtNroFactura] = useState("");
	const [disableBtnRefresh1, setDisableBtnRefresh1] = useState(true);
	const [disableBtnRefresh2, setDisableBtnRefresh2] = useState(true);
	const [tipoFacturaDialog, setTipoFacturaDialog] = useState(false);
	const [facturaDialog, setFacturaDialog] = useState(false);
	const [facturaData, setFacturaData] = useState([]);
	const [tipoFacturaImp, setTipoFacturaImp] = useState("");
	const [margenFactura, setMargenFactura] = useState([]);
	const [margenFacturaFL, setMargenFacturaFL] = useState([]);
	const [margenFacturaFD, setMargenFacturaFD] = useState([]);
	const [loadingDialog, setLoadingDialog] = useState(false);
	const [pagoDivisasDialog, setPagoDivisasDialog] = useState(false);
	const [IGTF, setIGTF] = useState([]);
	const [tasaCambioToBs, setTasaCambioToBs] = useState([]);
	const [pagos, setPagos] = useState([]);
	const [disabledIGTF, setDisabledIGTF] = useState(false);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    
	const validate = (fieldValues = valuesPro) => {
        let temp = { ...errorsPro };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";
		
        if ("txtCantidad" in fieldValues) temp.txtCantidad = (fieldValues.txtCantidad && fieldValues.txtCantidad !== undefined && fieldValues.txtCantidad > 0) ? "" : "Campo Requerido.";
        if ("txtPrecioUnit" in fieldValues) temp.txtPrecioUnit = fieldValues.txtPrecioUnit ? "" : "Campo Requerido.";
        if ("txtDescripcion" in fieldValues) temp.txtDescripcion = fieldValues.txtDescripcion ? "" : "Campo Requerido.";
        
        setErrorsPro({
            ...temp,
        });

        if (fieldValues === valuesPro) return Object.values(temp).every((x) => x === "");
    };

	const onValuesProChange = (e) => {
		const { name, value } = e.target
        setValuesPro({
            ...valuesPro,
            [name]: value
        })
	}

	const onValuesPagoChange = (e) => {
		const { name, value } = e.target
        setValuesPago({
            ...valuesPago,
            [name]: value
        })
	}
   
    const validate2 = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboCliente" in fieldValues) temp.cboCliente = fieldValues.cboCliente ? "" : "Campo Requerido.";
        if ("txtFechaFactura" in fieldValues) temp.txtFechaFactura = fieldValues.txtFechaFactura ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
		if (userData.usaMF){
			if ("cboMaquinaFiscal" in fieldValues) temp.cboMaquinaFiscal = fieldValues.cboMaquinaFiscal  ? "" : "Campo Requerido.";
		}
        
		setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

	const validatePreFactura = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboCliente" in fieldValues) temp.cboCliente = fieldValues.cboCliente ? "" : "Campo Requerido.";
        if ("txtFechaFactura" in fieldValues) temp.txtFechaFactura = fieldValues.txtFechaFactura ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
		setErrors({
            ...temp,
        });
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

	useEffect(() => {
		setLoadingDialog(true);
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
		httpService.getOptionSelect("lista_maquinasFiscales", userData.contribuyente)
		.then(data => {setMaquinasFiscalesOptions(data);})
		.catch(err => {});
        httpService.getOptionSelect("lista_UoM").then(data => setUoMOptions(data));
        httpService.getOptionSelect("lista_productos_venta", userData.contribuyente, '').then(data => setProductoOptions(data));
        httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
        httpService.getOptionSelect("lista_NoBolivar").then(data => setMonedaSBOptions(data));
        httpService.getOptionSelect("lista_clientes", userData.contribuyente).then(data => setClienteOptions(data));
        httpService.getOptionSelect("lista_impuestos").then(data => setImpuestosOptions(data));
        httpService.getOptionSelect("lista_impuestos_IGTF").then(data => setImpuestosIGTFOptions(data));
        httpService.getOptionSelect("lista_formasDePago").then(data => setFormasDePagoOptions(data));
        httpService.getOptionSelect("lista_condicionesDePago").then(data => setCondicionesDePagoOptions(data));
        
		httpService.getNextCorrelativo("Factura", "sNroControl", userData.contribuyente, null).then(data => { 
			setDisableBtnRefresh1(false); 
			if (data===""){
				swal({ title: "Ups...", text: "No existe un correlativo para el Nro. de Control, debes registrar uno primero.", icon: "error", closeOnClickOutside: false });
			} else {
				setTxtNroControl(data);
			}
		});
		httpService.getNextCorrelativo("Factura", "sNroFactura", userData.contribuyente, null).then(data => { 
			setDisableBtnRefresh2(false);
			if (data===""){
				swal({ title: "Ups...", text: "No existe un correlativo para el Nro. de Factura, debes registrar uno primero.", icon: "error", closeOnClickOutside: false });
			} else {
				setTxtNroFactura(data)
			}
			
		});
		httpService.getGeneralData("margenes_factura_FL").then((data) => setMargenFacturaFL(data));
		httpService.getGeneralData("margenes_factura_FD").then((data) => setMargenFacturaFD(data));
		httpService.getGeneralData("tasa_cambio_to_BS", {"txtContribuyente": userData.contribuyente, "monedaF": 0}).then(data => { 
			console.log(data);
			setTasaCambioToBs(data) 
			setLoadingDialog(false);
		})

		/******************************************************************************************* */
		// Carga data de factura en caso de edición (pre-facturas)
		console.log(location.pathname); 
		if (location.hasOwnProperty("state") && location.state!==""){
			httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
			setLoadingDialog(true);
			let fecha_f = location.state.data.txtFechaFactura.split("/");
			location.state.data.txtFechaFactura = new Date(fecha_f[2], fecha_f[1] - 1, fecha_f[0]);
			setValues(location.state.data);
			console.log(location.state.data)
			location.state.data.txtNombreCliente = location.state.data.txtCliente;
			setDataClienteSelected(location.state.data);
			
			setIdFactura(location.state.data.id);
			let items = location.state.data.items;
			array_aux.length = 0;
			items.forEach(pro => {
				if (pro.id !== 0) {
					array_aux.push(pro.id);
				}
			});
			let array_string = array_aux.toString();
			httpService.getTableSelect("tabla_item_facturaP", userData.contribuyente, array_string)
			.then(data => {
				setProductos(data);
				setLoadingDialog(false);
			})
			.catch(err => console.log(err));

			let IGTF = location.state.data.IGTF;
			setIGTF(IGTF);
			let a_pagos = location.state.data.pagos;
			setPagos(a_pagos);
			console.log(a_pagos);
			

		} else {
			array_aux.length = 0;
		}


    }, [httpService, location, setValues, userData]);

    const handleSubmit = async (e, accion) => {
        setSubmitted(true);
		if (accion==='factura') 
			setGenerarOrdenDialog(false) 
		else 
			setPreFacturarDialog(false)
		
        e.preventDefault();
		values.txtContribuyente = userData.contribuyente;
		values.nIdUsuario_aux = userData.idUsuario;
		values.caso = caso;
		values.id = idFactura;
		values.txtNombreCliente = dataClienteSelected.txtNombreCliente;
		values.txtRifCliente = dataClienteSelected.txtRifCliente;
		values.txtDireccionCliente = dataClienteSelected.txtDireccionCliente;
		values.txtTelefonoCliente = dataClienteSelected.txtTelefonoCliente;
		values.txtNroControl = txtNroControl;
		values.txtNroFactura = txtNroFactura;
		values.sPctIGTF = IGTF.pct;
		values.sMonedaIGTF = IGTF.sMoneda;
		values.pagos = pagos;

			let arr_maquina = maquinasFiscalesOptions.filter((mf) => mf.id === values.cboMaquinaFiscal );
			arr_maquina.forEach(mf => {
				values.sMaquinaFiscal = mf.title;	
			});
			
        if (array_aux.length > 0) {
			if (accion==='prefactura') {
				if (validatePreFactura()){
					setButtonDisabled(true);
					setLoadingDialog(true);
					
					let array_string_aux = array_aux.toString();
					values.items = array_string_aux;
					httpService.registrarPreFactura(values)
					.then((res) => {
						setButtonDisabled(false);
                        if (res.error) {
							setLoadingDialog(false);
                            swal({title: "Ups...", text: res.message, icon: "error"});
                        } else {
							setLoadingDialog(false);
							setSubmitted(false);
							setIGTF([]);
							setProductos([]);
							setPagos([]);
							resetDatosCli();
							resetForm();
							array_aux.length = 0;
							swal("Exito", res.message, "success")
							.then((value) => {
								setTimeout(() => {
									history.push("/consulta_de_ventas");	
								}, 100);
							});  

						}

					})
                    .catch((err) => {
						setLoadingDialog(false);
						swal({ title: "Ups...", text: err.message, icon: "error" });
                        setButtonDisabled(false);
                    });
				}
				else {
					swal({ title: "Ups...", text: "Verifique los datos faltantes de la Pre-Factura", icon: "error" });
					setPreFacturarDialog(false);
				}
			} 
			else 
			if (validate2()) {
                setButtonDisabled(true);
				setLoadingDialog(true);
				console.log("Items de pagos");
				console.log(values);

                httpService.registrarNuevaVenta(values)
                    .then((res) => {
                        setButtonDisabled(false);
                        if (res.error) {
							
							setLoadingDialog(false);
                            swal({title: "Ups...", text: res.message, icon: "error"});
                        } else {
							let msj_exito = res.message;
                            let array_string_aux = array_aux.toString();
                            
                            if (res.nId_db !== 0) {
                                array_string_aux = array_string_aux + '||' + res.nId_db;
								httpService.asociarItemsVenta(array_string_aux)
                                .then((res) => {
                                    if (res.error) {
										setLoadingDialog(false);
                            			swal({ title: "Ups...", text: res.message, icon: "error" });
                                    } else {
										let msj_final = res.message === "" ? msj_exito : msj_exito + " Nota: " + res.message;
										setLoadingDialog(false);
										setSubmitted(false);
										setIGTF([]);
										setProductos([]);
										setPagos([]);
										resetDatosCli();
										resetForm();
                                        array_aux.length = 0
                                        /* let array_string = array_aux.toString(); */
										swal("Exito", msj_final, "success")
										.then((value) => {
											if (res.imprimirFact){
												printFactura(res.data);
											} else {
												setTimeout(() => {
													history.push("/consulta_de_ventas");	
												}, 100);
											}
										});  
                                    }
                                })
                                .catch((err) => {
									setLoadingDialog(false);
									swal({ title: "UY...", text: err.message, icon: "error" });
                                    setButtonDisabled(false);
                                });
                            } else {
								setLoadingDialog(false);
								swal({ title: "Ups...", text: "Ha ocurrido un error, intente mas tarde", icon: "error" });
                        		setButtonDisabled(false);
							}
                        }
                    })
                    .catch((err) => {
						setLoadingDialog(false);
						swal({ title: "Ups...", text: err.message, icon: "error" });
                        setButtonDisabled(false);
                    });
            } else {
				swal({ title: "Ups...", text: "Verifique los datos faltantes de la Factura", icon: "error" });
                setGenerarOrdenDialog(false);
            }
        } else {
            swal({ text: "No hay Items de ventas cargados", icon: "error", timer: "3000" });
        }

    };

    const AgregarItem = async (e) => {
        e.preventDefault();
		setSubmitted(true);
		setProductoDialog(false);
        if (validate()) {
			if (values.cboMoneda !== valuesPro.cboMonedaIt){
				setProductoDialog(true);
				swal("¡Atención!", "No puedue agregar un Item cuya moneda no coincide con la usada en esta factura.", "warning");
			} else {
				setButtonDisabled(true);
				valuesPro.txtContribuyente = userData.contribuyente;
				valuesPro.nIdUsuario_aux = userData.idUsuario;
				setLoadingDialog(true);
				httpService.agregarItemFactura(valuesPro)
					.then((res) => {
						setButtonDisabled(false);
						if (res.error) {
							setLoadingDialog(false);
							setProductoDialog(true);
							swal({ text: res.message, icon: "error",  });
						} else {
							
							if (res.nId_db !== 0) {
								array_aux.push(res.nId_db);
							}
							let array_string = array_aux.toString();
							httpService.getTableSelect("tabla_item_facturaP", userData.contribuyente, array_string)
							.then(data => {
								setProductos(data);
								setLoadingDialog(false);
							})
							.catch(err => setLoadingDialog(false));
						}
					})
					.catch((err) => {
						setLoadingDialog(false);
						swal({ text: err.message, icon: "error", mtimer: "3000" });
						setButtonDisabled(false);
					});
			}
        } else {
			console.log("error al validar")
			setProductoDialog(true);
		}

    }

    const openNew = () => {
		let val = {...values};
		if (val.cboMoneda === ''){
			swal({ text: "Debe seleccionar la moneda antes de agrear Items.", icon: "error" });

		} else {
			setCaso(0);
			setDisabled(false);
			setSubmitted(false);
			resetDatosProducto();
			setProductoDialog(true);
		}
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductoDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Agregar Item" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    }

	const leftToolbarTemplateP = () => {
        return (
            <React.Fragment>
                <Button label="Agregar Pago" icon="pi pi-plus" className="p-button-warning p-mr-2" onClick={()=> showDialogPago(setPagoDivisasDialog)} disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    }

    /* const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Productos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    ); */

    const ProductosDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Agregar" icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabled} onClick={AgregarItem} />
        </>
    );

	const AgregarPago = () => {
		if (valuesPago.cboFormaDePago===0) {
			swal({ text: "Debe Indicar Forma de Pago.", icon: "error" });
			return;
		}
		if (valuesPago.txtMontoDivisas===""){
			swal({ text: "Debe indicar Monto.", icon: "error" });
			return;
		}
		if (valuesPago.cboMonedaDivisa===0){
			swal({ text: "Indique la moneda.", icon: "error" });
			return;
		}

		if (valuesPago.cboFormaDePago!=="" && valuesPago.txtMontoDivisas!==0 && valuesPago.cboMonedaDivisa!==""){
			let monto = valuesPago.txtMontoDivisas;
			let rs_moneda = MonedaOptions.find( pro => pro.id === valuesPago.cboMonedaDivisa );
			let rs_formaPago = formasDePagoOptions.find( pro => pro.id === valuesPago.cboFormaDePago );
			let indice = pagos.length + 1;
			
			if (valuesPago.cboImpuestoIGTF!=="" && valuesPago.cboImpuestoIGTF!==0){
				let resultado = impuestosIGTFOptions.find( pro => pro.id === valuesPago.cboImpuestoIGTF );
				let pct_igtf = resultado.valor;
				let total_igtf = 0;
				httpService.getGeneralData("tasa_cambio_to_BS", {"txtContribuyente": userData.contribuyente, "monedaF": valuesPago.cboMonedaDivisa})
				.then(data => { 
					let tasaCambio = data["deTasa"];
					if (values.cboMoneda === 27){
						total_igtf = (monto * tasaCambio) * pct_igtf / 100;	
					} else {
						total_igtf = monto * pct_igtf / 100;
					}
					let arr_igtf = {
						"divisas": monto,
						"pct" : pct_igtf,
						"total" : total_igtf,
						"moneda" : valuesPago.cboMonedaDivisa,
						"sMoneda" : rs_moneda.title
					}
					setIGTF(arr_igtf);
					let arr_pago = {
						"id": indice,
						"deMontoPago": monto,
						"moneda" : valuesPago.cboMonedaDivisa,
						"sMoneda" : rs_moneda.title,
						"formaDePago" : valuesPago.cboFormaDePago,
						"sFormaDePago" : rs_formaPago.title,
						"pct" : pct_igtf,
						"totalIGTF" : total_igtf,
						"nroSoporte" : valuesPago.txtSoportePago===undefined ? "" : valuesPago.txtSoportePago
					}
					pagos.push(arr_pago);
					setPagos(pagos);
					setPagoDivisasDialog(false);
				})
			} else {
				let arr_pago = {
					"id": indice,
					"deMontoPago": monto,
					"moneda" : valuesPago.cboMonedaDivisa,
					"sMoneda" : rs_moneda.title,
					"formaDePago" : valuesPago.cboFormaDePago,
					"sFormaDePago" : rs_formaPago.title,
					"pct" : 0,
					"totalIGTF" : 0,
					"nroSoporte" : valuesPago.txtSoportePago===undefined ? "" : valuesPago.txtSoportePago
				}
				pagos.push(arr_pago);
				setPagos(pagos);
				setPagoDivisasDialog(false);
			}
		} else {
			swal({ text: "Debe llenar todos los campos.", icon: "error" });
		}
	}


	const pagoDivisasDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setPagoDivisasDialog)} />
            <Button label="Agregar" icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabled} onClick={AgregarPago} />
        </>
    );

    const ProductoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sProducto}
            </>
        );
    }

    const UoMBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sUoM}
            </>
        );
    }

    const CantidadBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtCantidad)}`;
    }

    const PrecioUnitBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtPrecioUnit)} ${rowData.sMoneda}`;
    }

    const ImpuestoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sImpuesto}
            </>
        );
    }
	
	const DescuentoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.dePctDescuento + ' %'}
            </>
        );
    }

    const SubTotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSubTotal)} ${rowData.sMoneda}`;
    }

	const MontoPagoBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.deMontoPago)} ${rowData.sMoneda}`;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-mr-2" onClick={() => confirmDeleteSelected(rowData)} />
            </div>
        );
    }

    const actionPagoBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deletePago(rowData)} />
            </div>
        );
    }

    const ProductoOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.title}</div>
            </div>
        );
    }

    const selectedProductoTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.title}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const [deleteProductosDialog, setDeleteProductosDialog] = useState(false);
    const [generarOrdenDialog, setGenerarOrdenDialog] = useState(false);
    const [preFacturarDialog, setPreFacturarDialog] = useState(false);

    /* const [globalFilter, setGlobalFilter] = useState(null); */
    const dt = useRef(null);

    const hideDeleteProductosDialog = () => {
        setDeleteProductosDialog(false);
    }

    const hideGenerarOrdenDialog = () => {
        setGenerarOrdenDialog(false);
    }
    const hidePreFacturarDialog = () => {
        setPreFacturarDialog(false);
    }

    const confirmDeleteSelected = (producto) => {
        setProducto(producto);
        setDeleteProductosDialog(true);
    }

    const confirmVenta = (producto) => {
        setProducto(producto);
        setGenerarOrdenDialog(true);
    }

	/* const preFacturar = (producto) => {
        setProducto(producto);
		setPreFacturarDialog(true);
    } */

    const deleteSelectedProductos = async (e) => {
        producto.nIdContribuyente = userData.contribuyente;
        e.preventDefault();
        httpService.deleteItemFactura(producto)
            .then((res) => {
                if (res.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    setDeleteProductosDialog(false);
                    let array_string = array_aux.toString();
                    httpService.getTableSelect("tabla_item_facturaP", userData.contribuyente, array_string).then(data => setProductos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deletePago = (data) => {
		let pagos_aux = pagos.filter((item) => item.id !== data.id )
		setPagos(pagos_aux);
    }

    const deleteProductosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProductos} />
        </>
    );

    const generarOrdenDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideGenerarOrdenDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={(e) => handleSubmit(e, 'factura')} />
        </>
    );
	const preFacturarDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hidePreFacturarDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={(e) => handleSubmit(e, 'prefactura')} />
        </>
    );

    const formatCurrency = function (number) {
		
		const options = { minimumFractionDigits: 2, maximumFractionDigits: 2}
		const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
		const numero = numberFormat1.format(number);
		return numero;
    };

    const Nsi = () => {
        let total = 0;
		let sMoneda = '';
        for (let mont of productos) {
            total += (parseFloat(mont.txtSubTotal));
			sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + ' ' + sMoneda;
    }

	var groupBy = function (miarray, prop) {
		return miarray.reduce(function(groups, item) {
			var val = item[prop];
			groups[val] = groups[val] || {sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0};
			groups[val].deImpuesto += parseFloat(item.deImpuesto);
			groups[val].sMoneda = item.sMoneda;
			groups[val].deBaseImpon += parseFloat(item.txtSubTotal);
			
			return groups;
		}, {});
	}

	const grupoImpuesto = () => {
		let grImp = groupBy(productos, 'sImpuesto');
		let refinado = Object.values(grImp).filter(i => i.sImpuesto !== "Exento")

		return refinado;
    }

    const Total = () => {
        let total = 0;
		let sMoneda = '';

		if (array_aux.length > 0){
			for (let mont of productos) {
				total += parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto);
				sMoneda = mont.sMoneda;
			}
			for (let pago of pagos) {
				total += pago.totalIGTF !== undefined ? pago.totalIGTF : 0;
			}
		}
		
		return formatCurrency(total) + ' ' + sMoneda;
    }

	const Total_con = () => {
        let total = 0;
		let sMoneda = '';
		let result = '';
		let tot_mon = [];

		if (array_aux.length > 0 && tasaCambioToBs.length > 0){
			MonedaOptions.forEach(mon => {
				tot_mon[mon.id] = 0;
			});
			if (values.cboMoneda === 0){

			} else if (values.cboMoneda !== 27){ // Factura en divisas convierto a Bs
				let m = MonedaOptions.find( m => m.id === 27 );
				sMoneda = m.title;
				for (let mont of productos) {
					let tasaC = tasaCambioToBs.find( tc => tc.moneda === mont.moneda );
					if ( tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa)))
						total += (parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto)) * tasaC.tasa;
				}
				for (let pago of pagos) {
					if (pago.totalIGTF !== undefined && pago.totalIGTF !== 0){
						let tasaC = tasaCambioToBs.find( tc => tc.moneda === pago.moneda );
						if ( tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa)) )
							total +=  pago.totalIGTF * tasaC.tasa;
					}
				}
				tot_mon[values.cboMoneda] += total;
				result = formatCurrency(total) + ' ' + sMoneda;
			} else { // Factura en Bs llevó a Divisas
				MonedaSBOptions.forEach(mon => {
					sMoneda = mon.title;
					
					for (let mont of productos) {
						total += (parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto)) ;
					}
					for (let pago of pagos) {
						if (pago.totalIGTF !== undefined){
							total +=  pago.totalIGTF ;
						}
					}
					let totalF = 0;
					if (tasaCambioToBs.length > 0){
						let tasaC = tasaCambioToBs.find( tc => tc.moneda === mon.id );
						if ( tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa)))
							totalF = total / tasaC.tasa;
					} else {
						totalF = total;
					}
					if (totalF !== 0) {
						result += formatCurrency(totalF) + ' ' + sMoneda + " / ";
						tot_mon[mon.id] += total;
					}
					
				}); 
				result = result.substring(0, result.length - 2);
			}
		}
        return result;
    }

	const TotalFooter_con = () => {
        let total = 0;
		let sMoneda = '';
		let result = '';
		let tot_mon = [];

		//Obtengo total en modena original
		let totalOriginal = 0;
		let sMonedaOriginal = '';
		let ResulOriginal = '';

		if (array_aux.length > 0){
			for (let mont of productos) {
				totalOriginal += parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto);
				sMonedaOriginal = mont.sMoneda;
			}
			for (let pago of pagos) {
				totalOriginal += pago.totalIGTF !== undefined ? pago.totalIGTF : 0;
			}
		}
		
		ResulOriginal = formatCurrency(totalOriginal) + ' ' + sMonedaOriginal;

		if (array_aux.length > 0 && tasaCambioToBs.length > 0){
			MonedaOptions.forEach(mon => {
				tot_mon[mon.id] = 0;
			});
			if (values.cboMoneda === 0){

			} else if (values.cboMoneda !== 27){ // Factura en divisas convierto a Bs
				let m = MonedaOptions.find( m => m.id === 27 );
				sMoneda = m.title;
				for (let mont of productos) {
					let tasaC = tasaCambioToBs.find( tc => tc.moneda === mont.moneda );
					if ( tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa)))
						total += (parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto)) * tasaC.tasa;
				}
				for (let pago of pagos) {
					if (pago.totalIGTF !== undefined && pago.totalIGTF !== 0){
						let tasaC = tasaCambioToBs.find( tc => tc.moneda === pago.moneda );
						if ( tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa)) )
							total +=  pago.totalIGTF * tasaC.tasa;
					}
				}
				tot_mon[values.cboMoneda] += total;
				result =  formatCurrency(total) + ' ' + sMoneda + ' / ' + ResulOriginal;
			} else { // Factura en Bs llevó a Divisas
				MonedaSBOptions.forEach(mon => {
					sMoneda = mon.title;
					
					for (let mont of productos) {
						total += (parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto)) ;
					}
					for (let pago of pagos) {
						if (pago.totalIGTF !== undefined){
							total +=  pago.totalIGTF ;
						}
					}
					let totalF = 0;
					if (tasaCambioToBs.length > 0){
						let tasaC = tasaCambioToBs.find( tc => tc.moneda === mon.id );
						if ( tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa)))
							totalF = total / tasaC.tasa;
					} else {
						totalF = total;
					}
					if (totalF !== 0) {
						result += formatCurrency(totalF) + ' ' + sMoneda;
						tot_mon[mon.id] += total;
					}
					
				}); 
				result = ResulOriginal + ' / ' + result;
			}
		}
        return result;
    }

	const TotalPagos_con = () => {
		let result = '';
		let tot_mon = [];

		if (array_aux.length > 0 && pagos.length > 0 && tasaCambioToBs.length > 0){
			MonedaOptions.forEach(mon => {
				tot_mon[mon.id] = 0;
			});

			for (let pago of pagos) {
				let monA = pago.moneda;
				MonedaOptions.forEach(mon => {
					if (monA === mon.id) {
						tot_mon[mon.id] += pago.deMontoPago * 1;
					} else {
						if (monA === 27) {
							let tasaC = tasaCambioToBs.find( tc => tc.moneda === mon.id);
							if (tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa))) {
								tot_mon[mon.id] += pago.deMontoPago / tasaC.tasa;
							}
						} else {
							if (mon.id === 27) {
								let tasaC = tasaCambioToBs.find( tc => tc.moneda === monA);
								if (tasaC !== undefined && (tasaC.tasa !== 0 && !isNaN(tasaC.tasa))) {
									tot_mon[mon.id] += pago.deMontoPago * tasaC.tasa;
								}
							} else {
								let tasaA = tasaCambioToBs.find( tc => tc.moneda === monA);
								let tasaB = tasaCambioToBs.find( tc => tc.moneda === mon.id);
								if ((tasaA !== undefined && (tasaA.tasa !== 0 && (!isNaN(tasaA.tasa)))) && (tasaB !== undefined && (tasaB.tasaM !== 0 && (!isNaN(tasaB.tasaM))))) {
									tot_mon[mon.id] += pago.deMontoPago * tasaA.tasa / tasaB.tasaM;
								}
							}
							
						}
					}
				}); 
				
			}
			
			tot_mon.forEach((p, i) => {
				let m = MonedaOptions.find( m => m.id === i );
				result += formatCurrency(p) + ' ' + m.title + ' / '; 
			});
			result = result.substring(0, result.length - 2);
		}
        return result;
    }

	const TotalResto = () => {
		let result = '';

		if (array_aux.length > 0 &&  pagos.length > 0 && MonedaOptions.length > 0){

			let pagos = TotalPagos_con();
			let factura = Total_con() + " / " + Total();
			let a_pagos = pagos.split("/");
			let a_facturas = factura.split("/");

			let a_total_fact = [];
			a_facturas.forEach(p => {
				if (p.trim() !== "" && p.trim() !== "0,00"){
					let a_fact = p.trim().split(" ");
					let titulo = "";
					if (a_fact.length > 1) {
						titulo = a_fact[1].trim();
						let m = MonedaOptions.find( m => m.title === titulo );
						a_total_fact[m.id] = a_fact[0];
					}else{
						titulo = "";
					}

				}
			});
			console.log("totalFacturas", a_total_fact)

			let a_total_pago = [];

			a_pagos.forEach(p => {
				if (p.trim() !== "" && p.trim() !== "0,00"){
					let a_pago = p.trim().split(" ");
					if (a_pago[0] !== "0,00"){
						let titulo = a_pago[1].trim();
						let m = MonedaOptions.find( m => m.title === titulo );
						a_total_pago[m.id] =  a_pago[0];
					}
				}
			});
			// Aquí empieza la resta 
			a_total_fact.forEach((p, i) => {
				let resta = 0;
				let m = MonedaOptions.find( m => m.id === i );
				if (a_total_pago.length > 0){
					let fact = parseFloat((p.replace(".", "")).replace(",", "."));
					let pago = parseFloat((a_total_pago[i].replace(".", "")).replace(",", "."));
					resta = fact - pago;

				}
				result += formatCurrency(resta) + ' ' + m.title + ' / '; 
			});
			
			result = result.substring(0, result.length - 2);
		}
        return result;
    }

	const TotalIGTF = () => {
		var rs_moneda = [];
		let total = 0;
		let sMoneda = '';

		if (array_aux.length > 0){
			for (let pago of pagos) {
				if (values.cboMoneda === 27){
					rs_moneda = MonedaOptions.find( pro => pro.id === values.cboMoneda );
				} else {
					rs_moneda = MonedaOptions.find( pro => pro.id === pago.moneda );
				}
				if (pago.totalIGTF !== undefined){
					total += pago.totalIGTF;
					sMoneda = rs_moneda.title;
				}
			}
		}
		return formatCurrency(total) + ' ' + sMoneda;
	}

    const clienteOptionTemplate = (option) => {
        return (
                <div> {option.title}</div>
        );
    }

	const cargarDatosCli = (e) => {
		let idCli = e.target.value;
		let val = {};
		
		if (idCli !== undefined) {
			const resultado = ClienteOptions.find( cli => cli.id === idCli );
			val.txtNombreCliente = resultado.title;
			val.txtDireccionCliente = resultado.sDireccion;
			val.txtRifCliente = resultado.sRif;
			val.txtTelefonoCliente = resultado.sTelefono;
			setDataClienteSelected(val);
		} else {
			val.txtNombreCliente = '';
			val.txtDireccionCliente = '';
			val.txtRifCliente = '';
			val.txtTelefonoCliente = '';
			setDataClienteSelected(val);
		}
    }

	const verificarIGTF = (e) => {
		let id = e.target.value;
		let val = { ...valuesPro};
		
		if (id !== undefined) {
			if (id === 27){
				setDisabledIGTF(true);
				val.cboImpuestoIGTF = '';
			}else{
				setDisabledIGTF(false);
			}
		}
	}

	const cargarDatosProducto = (e) => {
		let idPro = e.target.value;
		let val = { ...valuesPro};
		
		if (idPro !== undefined) {
			const resultado = productoOptions.find( pro => pro.id === idPro );
			val.cboProducto = idPro;
			val.txtDescripcion = resultado.sDescripcion;
			val.cboUoM = resultado.nUoM;
			val.txtPrecioUnit = resultado.dePrecio;
			val.cboImpuesto = resultado.nIdImpuesto;
			val.txtPrecioTotal = resultado.dePrecioTotal;
			val.cboMonedaIt = resultado.nIdMoneda;
			val.deCantidad = resultado.deCantidad;
			val.nEsInventariable = resultado.nEsInventariable;
		} else {
			val.cboProducto = idPro;
			val.txtDescripcion = '';
			val.cboUoM = '';
			val.txtPrecioUnit = 0;
			val.txtPrecioTotal = 0;
			val.cboImpuesto = '';
			val.cboMonedaIt = '';
			val.deCantidad = 0;
			val.nEsInventariable = '';
		}
		setValuesPro(val);
    }

	const resetDatosProducto = () => {
		let val = { ...valuesPro};
		val.cboProducto = '';
		val.txtCantidad = 0;
		val.cboUoM = '';
		val.txtPrecioUnit = 0;
		val.txtPrecioTotal = 0;
		val.cboMonedaIt = '';
		val.cboImpuesto = '';
		val.txtPctDescuento = 0;
		val.txtDescripcion = '';
		setValuesPro(val);
    }

	const resetDatosCli = () => {
		let val = { ...values};
		val.txtNombreCliente = '';
		val.txtDireccionCliente = '';
		val.txtRifCliente = '';
		val.txtTelefonoCliente = '';
		setDataClienteSelected(val);
    }

    const selectedClienteTemplate = (option, props) => {
        if (option) {
            return (
				<div> {option.title}</div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const footerGroup = (<ColumnGroup>
        <Row>
            <Column footer="Subtotal:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Nsi} footerStyle={{ textAlign: 'right' }} />
			<Column />
        </Row>

		{ grupoImpuesto().map((post) =>
        	<Row>
				<Column footer={`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)} ${post.sMoneda}): `} colSpan={6} footerStyle={{ textAlign: 'right' }} />
				<Column footer={formatCurrency(post.deImpuesto) + ' ' + post.sMoneda} footerStyle={{ textAlign: 'right' }} />
				<Column />
			</Row>
      	) }
		{ IGTF.total && 
		  <Row>
            <Column footer={"IGTF " + IGTF.pct + "%"} colSpan={6} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalIGTF} footerStyle={{ textAlign: 'right' }} />
			<Column />
        </Row> 
		}
        <Row>
            <Column footer="TOTAL:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Total} footerStyle={{ textAlign: 'right' }} />
			<Column />
        </Row>
		<Row>
            <Column footer="" colSpan={6} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Total_con} footerStyle={{ textAlign: 'right' }} />
			<Column />
        </Row>
    </ColumnGroup>
	);

	let footerGroupPagos = <ColumnGroup>
		
        <Row>
            <Column footer="Total Pagos:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
            
			<Column footer={`${TotalPagos_con()}`} footerStyle={{ textAlign: 'center' }}/>
        </Row>
		
        <Row>
            <Column footer="Total Factura:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
            <Column footer={`${TotalFooter_con()}`} footerStyle={{ textAlign: 'center' }}/>
        </Row>
		
        <Row>
            <Column footer="Restan por pagar:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalResto} footerStyle={{ textAlign: 'center' }}/>
        </Row>
		
    </ColumnGroup>;

	const refreshNroControl = () => {
		setDisableBtnRefresh1(true);
		httpService.getNextCorrelativo("Factura", "sNroControl", userData.contribuyente, null)
			.then(data => {setDisableBtnRefresh1(false); setTxtNroControl(data)});
	}

	const refreshNroFactura = () => {
		setDisableBtnRefresh2(true);
		httpService.getNextCorrelativo("Factura", "sNroFactura", userData.contribuyente, null)
			.then(data => { setDisableBtnRefresh2(false); setTxtNroFactura(data)});
	}

	const printFactura = (venta) => {
		let usaMaquinaFiscal = userData.usaMF;
		if ( usaMaquinaFiscal===1 ) {
			swal({ title: "Ups...", text: "Su venta ha sido registrada satisfactoriamente. Sin embargo, la Factura Fiscal no puede ser emitida. Recuerde que, al usar máquina fiscal, solo puede usar su talonario alternativo como otro medio de facturación.", icon: "warning" });
			history.push("/consulta_de_ventas");
		}
		else {
			httpService.getTableSelect("tabla_item_facturaC", userData.contribuyente, venta.id)
			.then((data) => {
				venta.items = data;
				setFacturaData(venta);
				setTipoFacturaDialog(true)
			});
		}
	}

	const onShow = (stateMethod, tipoImp) => {
		setTipoFacturaImp(tipoImp);
		if (tipoImp === 'FL'){
			let margen = { sup : margenFacturaFL.MargenSupFormaLibre, inf : margenFacturaFL.MargenInfFormaLibre	}
			setMargenFactura(margen);
		} else {
			let margen = { sup : margenFacturaFD.MargenSupFacturaDigital, inf : margenFacturaFD.MargenInfFacturaDigital	}
			setMargenFactura(margen);
		}
		stateMethod(true);
    }

	
	const onHide = (stateMethod) => {
        stateMethod(false);
    }

	const showDialogPago = (stateMethod) => {
		setValuesPago(emptyPago)
        stateMethod(true);
    }

	const hideFacturaDialog = () => {
		setFacturaDialog(false);
        history.push("/consulta_de_ventas");
    }

	const renderFooter = (stateMethod) => {
        return (
            <div>
                <Button label="Factura Digital" icon="pi pi-file-o" onClick={() => { onHide(stateMethod); onShow(setFacturaDialog, 'FD')  }} />
                <Button label="Factura Forma Libre" icon="pi pi-print" onClick={() => { onHide(stateMethod); onShow(setFacturaDialog, 'FL') }} className="p-button-secondary"/>
            </div>
        );
    }

	return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Nueva Factura</h5>
                    <div className="p-fluid p-formgrid p-grid">
						<div className="p-field p-col-3">
                            <label htmlFor="txtNroControl">N° Control</label>
							<div className="p-inputgroup">
								<InputText size="small" name="txtNroControl" value={txtNroControl} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtNroControl })} />
                            	<Button icon="pi pi-refresh" disabled={disableBtnRefresh1} className="p-button-info" onClick={refreshNroControl}/>
                        	</div>
                            {submitted && !values.txtNroControl && <small className="p-invalid">{errors.txtNroControl}</small>}
                        </div>
						<div className="p-field p-col-3">
                            <label htmlFor="txtNroFactura">N° Factura</label>
							<div className="p-inputgroup">
                            	<InputText size="small" name="txtNroFactura" value={txtNroFactura} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtNroFactura })} />
								<Button icon="pi pi-refresh" disabled={disableBtnRefresh2} className="p-button-info" onClick={refreshNroFactura}/>
							</div>
                            {submitted && !values.txtNroFactura && <small className="p-invalid">{errors.txtNroFactura}</small>}
                        </div>
						<div className="p-field p-col-3">
                            <label htmlFor="txtFechaFactura">Fecha</label>
                            <Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="txtFechaFactura" name="txtFechaFactura" value={values.txtFechaFactura} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaFactura })} />
                            {submitted && !values.txtFechaFactura && <small className="p-invalid">{errors.txtFechaFactura}</small>}
                        </div>
						<div className="p-field p-col-3">
                            <label htmlFor="cboMaquinaFiscal">Máquina Fiscal</label>
                            <Dropdown id="cboMaquinaFiscal" name="cboMaquinaFiscal" value={values.cboMaquinaFiscal} onChange={handleInputChange} optionValue="id" optionLabel="title" options={maquinasFiscalesOptions} placeholder="Selecciona uno" disabled={userData.usaMF ? false : true} className={classNames({ 'p-invalid': submitted && userData.usaMF && !values.cboMaquinaFiscal })}></Dropdown>
                            {submitted && !values.cboMaquinaFiscal && <small className="p-invalid">{errors.cboMaquinaFiscal==="0" ? "" : errors.cboMaquinaFiscal}</small>}
                        </div>
						{/*  */}
                        <div className="p-field p-col-6">
                            <label htmlFor="cboCliente">Cliente</label>
                            <Dropdown id="cboCliente" name="cboCliente" value={values.cboCliente} onChange={(e) => {handleInputChange(e); cargarDatosCli(e)}} optionValue="id" optionLabel="title" options={ClienteOptions} filter showClear filterBy="title" placeholder="Selecciona uno" itemTemplate={clienteOptionTemplate} valueTemplate={selectedClienteTemplate} required className={classNames({ 'p-invalid': submitted && !values.cboCliente })}></Dropdown>
                            {submitted && !values.cboCliente && <small className="p-invalid">{errors.cboCliente}</small>}
                        </div>
                        <div className="p-field p-col-6">
                            <label htmlFor="txtRifCliente">RIF/DNI</label>
                            <InputText name="txtRifCliente" value={dataClienteSelected.txtRifCliente} disabled />
                        </div>
						{/*  */}
						<div className="p-field p-col-6">
                            <label htmlFor="txtDireccionCliente">Dirección</label>
                            <InputText name="txtDireccionCliente" value={dataClienteSelected.txtDireccionCliente} disabled />
                        </div>
						<div className="p-field p-col-6">
                            <label htmlFor="txtTelefonoCliente">Teléfono</label>
                            <InputText name="txtTelefonoCliente" value={dataClienteSelected.txtTelefonoCliente} disabled/>
                        </div>
						{/*  */}
                        <div className="p-field p-col-6">
                            <label htmlFor="cbo">Condición de Pago</label>
                            <Dropdown id="cboCondicionDePago" name="cboCondicionDePago" value={values.cboCondicionDePago} onChange={handleInputChange} optionValue="id" optionLabel="title" options={condicionesDePagoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboCondicionDePago })}></Dropdown>
                            {submitted && !values.cboCondicionDePago && <small className="p-invalid">{errors.cboCondicionDePago}</small>}
                        </div>
						<div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cboMoneda">Moneda</label>
                            <Dropdown id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMoneda })}></Dropdown>
                            {submitted && !values.cboMoneda && <small className="p-invalid">{errors.cboMoneda}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="txtObservacion">Observación</label>
                            <InputTextarea name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={productos} dataKey="id"  footerColumnGroup={footerGroup}>
                        <Column field="txtCantidad" header="Cant." headerStyle={{ width:'6%', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={CantidadBodyTemplate}></Column>
                        <Column field="cboUoM" header="UoM" headerStyle={{ width:'6%', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={UoMBodyTemplate}></Column>
                        <Column field="sProducto" header="Descripción" headerStyle={{ width:'35%' }} body={ProductoBodyTemplate}></Column>
                        <Column field="txtPrecioUnit" header="Precio Unit." headerStyle={{ width:'10%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }} body={PrecioUnitBodyTemplate}    ></Column>
						<Column field="txtPctDescuento" header="% Dcto." headerStyle={{ width:'10%', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={DescuentoBodyTemplate}></Column>
                        <Column field="cboImpuesto" header="Alicuota" headerStyle={{ width:'10%', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={ImpuestoBodyTemplate}></Column>
                        <Column field="txtSubTotal" header="Importe" headerStyle={{ width:'10%', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={SubTotalBodyTemplate}></Column>
                        <Column headerStyle={{ width:'4%' }} bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ProductoDialog} style={{ width: '550px' }} header="Seleccione el producto/servicio" modal className="p-fluid" footer={ProductosDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={valuesPro.id} onChange={onValuesProChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboProducto">Producto/Servicio</label>
                                <Dropdown id="cboProducto" name="cboProducto" value={valuesPro.cboProducto} onChange={ (e) => { onValuesProChange(e); cargarDatosProducto(e) }} optionValue="id" optionLabel="title" options={productoOptions} filter showClear filterBy="title" placeholder="Selecciona uno" itemTemplate={ProductoOptionTemplate} valueTemplate={selectedProductoTemplate} required autoFocus className={classNames({ 'p-invalid': submitted && !valuesPro.cboProducto })}></Dropdown>
                                {submitted && !valuesPro.cboProducto && <small className="p-invalid">{errorsPro.cboProducto}</small>}
                            </div>
                        </div>
                        
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtPrecioUnit">Precio</label>
								<InputNumber name="txtPrecioUnit" mode="decimal" minFractionDigits={2} value={valuesPro.txtPrecioUnit} onValueChange={onValuesProChange} required className={classNames({ 'p-invalid': submitted && !valuesPro.txtPrecioUnit })} />
                                {submitted && !valuesPro.txtPrecioUnit && <small className="p-invalid">{errorsPro.txtPrecioUnit}</small>}
                            </div>
							<div className="p-field p-col">
                                <label htmlFor="cboMonedaIt">Moneda</label>
                                <Dropdown id="cboMonedaIt" name="cboMonedaIt" value={valuesPro.cboMonedaIt} onChange={onValuesProChange} optionValue="id" optionLabel="title" options={MonedaOptions}></Dropdown>
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="cboImpuesto">IVA</label>
                                <Dropdown id="cboImpuesto" name="cboImpuesto" disabled value={valuesPro.cboImpuesto} onChange={onValuesProChange} optionValue="id" optionLabel="title" options={impuestosOptions} placeholder="Opcional" ></Dropdown>
                            </div>
							<div className="p-field p-col">
                                <label htmlFor="txtPrecioTotal">P.V.P</label>
                                <InputNumber name="txtPrecioTotal" disabled mode="decimal" minFractionDigits={2} value={valuesPro.txtPrecioTotal} onValueChange={onValuesProChange} />
                            </div>
                        </div>
						<div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtCantidad">Cantidad</label>
                                <InputNumber name="txtCantidad" mode="decimal" minFractionDigits={2} value={valuesPro.txtCantidad} onValueChange={onValuesProChange} required className={classNames({ 'p-invalid': submitted && !valuesPro.txtCantidad })} />
                                {submitted && !valuesPro.txtCantidad && <small className="p-invalid">{errorsPro.txtCantidad}</small>}
                            </div>
							<div className="p-field p-col">
                                <label htmlFor="cboUoM">UoM</label>
                                <Dropdown id="cboUoM" name="cboUoM" disabled value={valuesPro.cboUoM} onChange={onValuesProChange} optionValue="id" optionLabel="title" options={uoMOptions} placeholder="Selecciona uno" required></Dropdown>
                            </div>
							<div className="p-field p-col">
                                <label htmlFor="txtPctDescuento">% Dcto.</label>
                                <InputNumber name="txtPctDescuento" mode="decimal" minFractionDigits={2} value={valuesPro.txtPctDescuento} onValueChange={onValuesProChange} />
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtDescripcion">Descripción</label>
                                <InputTextarea name="txtDescripcion" disabled value={valuesPro.txtDescripcion} onChange={onValuesProChange}/>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductosDialogFooter} onHide={hideDeleteProductosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {producto && <span>Esta Seguro de Eliminar el producto seleccionado?</span>}
                        </div>
                    </Dialog>

					<Dialog visible={pagoDivisasDialog} style={{ width: '750px' }} contentStyle={{ height: '240px'}} header="Detalles Pago" modal className="p-fluid" footer={pagoDivisasDialogFooter} onHide={() => onHide(setPagoDivisasDialog)}>
                        <div className="p-formgrid p-grid">
							<div className="p-field p-col-6">
                            	<label htmlFor="cboFormaDePago">Forma de Pago</label>
                            	<Dropdown id="cboFormaDePago" name="cboFormaDePago" value={valuesPago.cboFormaDePago} onChange={onValuesPagoChange} optionValue="id" optionLabel="title" options={formasDePagoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !valuesPago.cboFormaDePago })}></Dropdown>
                            	{submitted && !valuesPago.cboFormaDePago && <small className="p-invalid">{errorsPago.cboFormaDePago}</small>}
                        	</div>
							<div className="p-field p-col-6">
								<label htmlFor="txtSoportePago">N° Soporte</label>
                            	<InputText name="txtSoportePago" value={valuesPago.txtSoportePago} onChange={onValuesPagoChange}/>
							</div>
                            <div className="p-field p-col-4">
                                <label htmlFor="txtMontoDivisas">Monto a pagar</label>
								<InputNumber name="txtMontoDivisas" mode="decimal" minFractionDigits={2} value={valuesPago.txtMontoDivisas} onValueChange={onValuesPagoChange}/>
                            </div>
							<div className="p-field p-col-4">
								<label htmlFor="cboMonedaDivisa">Moneda</label>
								<Dropdown id="cboMonedaDivisa" name="cboMonedaDivisa" value={valuesPago.cboMonedaDivisa} onChange={ (e) => { onValuesPagoChange(e); verificarIGTF(e) }} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" ></Dropdown>
                        	</div>
							<div className="p-field p-col-4">
                                <label htmlFor="cboImpuesto">IGTF</label>
                                <Dropdown disabled={disabledIGTF} id="cboImpuestoIGTF" name="cboImpuestoIGTF" value={valuesPago.cboImpuestoIGTF} onChange={onValuesPagoChange} optionValue="id" optionLabel="title" options={impuestosIGTFOptions} placeholder="Seleccione % IGTF"></Dropdown>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>

			<div className="p-col-12">
                <div className="card ">
				<Toolbar className="p-mb-4" left={leftToolbarTemplateP} ></Toolbar>
					<DataTable value={pagos} dataKey="id" width="100%" footerColumnGroup={footerGroupPagos}>
						<Column field="sFormaDePago" header="Forma Pago" headerStyle={{ width:'25%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }}  ></Column>
						<Column field="sNroSoporte" header="Nro Soporte" headerStyle={{ width:'25%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }}  ></Column>
						<Column field="sMoneda" header="Moneda" headerStyle={{ width:'25%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }} ></Column>
						<Column field="deMontoPago" header="Monto" headerStyle={{ width:'25%', textAlign: 'center'}} body={MontoPagoBodyTemplate} bodyStyle={{ textAlign: 'center' }} ></Column>
						<Column headerStyle={{ width:'4%' }} bodyStyle={{ textAlign: 'center' }} body={actionPagoBodyTemplate}></Column>
					</DataTable>
				</div>
			</div>
            <div className="p-col-12">
                <div className="card ">
					<div className="p-grid p-jc-end">
						<div className="p-col-2">
							<Button label="Generar Factura" className="p-button-success" onClick={confirmVenta} disabled={ acceso && acceso === "W" ? false : true } />
						</div>
					</div>
						
                </div>
            </div>
			<Dialog visible={generarOrdenDialog} style={{ width: '450px' }} header="Confirmar" modal footer={generarOrdenDialogFooter} onHide={hideGenerarOrdenDialog}>
				<div className="confirmation-content">
					<i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
					{producto && <span>¿Está seguro de registrar la Venta? Verifique la moneda seleccionada y recuerde que después no podrá editarla ni eliminarla. <br/><b>Nota: </b> Recuerde registrar una tasa de cambio actualizada.</span>}
				</div>
			</Dialog>
			<Dialog visible={preFacturarDialog} style={{ width: '450px' }} header="Confirmar" modal footer={preFacturarDialogFooter} onHide={hidePreFacturarDialog}>
				<div className="confirmation-content">
					<i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
					{producto && <span>¿Está seguro de guardar la pre-factura?</span>}
				</div>
			</Dialog>

			<Dialog header="Tipo de Factura" visible={tipoFacturaDialog} style={{width: '50vw'}} onHide={() => onHide(setTipoFacturaDialog)} footer={renderFooter(setTipoFacturaDialog)}>
                <p>Seleccione el Tipo de Factura que desea Imprimir: </p>
            </Dialog>
			
			<Dialog visible={facturaDialog} onHide={hideFacturaDialog} position="top" headerStyle={{ height: "1px" }} contentStyle={{maxHeight: "100vh", height: "100vh"}} resizable={true}>
				<PDFViewer  width="1000" height={"99%"} className="app" >
					<Factura 
						data={facturaData} 
						tipoImp={tipoFacturaImp} 
						margenes={margenFactura} 
						datosContrib={{ nombreC: userData.razon_social, rifC: userData.rif, direccionC: userData.direccion, telefonoC: userData.telfContribuyente, usuario: userData.nombre_completo, logo: userData.pathLogo }} />
				</PDFViewer>
			</Dialog>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
